<template>
  <review-card title="FT" :explanation="explanation">
    <v-list-item
      v-for="(item, index) in tumoralPhenomena"
      :key="index"
      class="py-2 item-par"
    >
      <v-list-item-content>
        <v-list-item-title class="mb-3">
          {{ item.tumoralPhenomenon.order }} - Zona de resolução:
          {{ item.diagnoseResolutionZone.resolutionZone.name }}
        </v-list-item-title>
        <property-value
          property="Ponto afetado"
          :value="item.diagnoseResolutionZone.affectedSpotParTrauma"
        />
        <pares-simple-list :pares="item.relatedPares" />
        <property-value
          property="Comentários"
          :value="item.patientComments"
          class="mt-3"
        />
      </v-list-item-content>
    </v-list-item>
  </review-card>
</template>

<script>
export default {
  name: "TherapyReviewTumoralPhenomena",
  components: {
    ParesSimpleList: () =>
      import(
        "@/domains/therapy/shared/presentation/components/ParesSimpleList"
      ),
  },
  props: {
    tumoralPhenomena: {
      type: Array,
      default: () => [],
    },
    explanation: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
